$default: #344675 !default;
$primary: #f3ff4b !default;
$semiwhite: rgba(#ffffff, 0.4) !default;
$semiblackT: #494949ab;
$semiblack: #494949;
$nearblackT: #1f1f1fab;
$nearblack: #1f1f1f;
$red: #fc5173 !default;

$contentPadding: 20px;
$headerHeight: 85px;
$valueFontSize: 17px;
$valueTextColor: white;
$labelFontSize: 15px;
$labelTextColor: rgb(146, 146, 146);
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

$secondary: #ffa068 !default;
$tertiary: #9daf8a !default;
$disabled: #583927 !default;
$disabled-inverse: #16463f !default;
$success: #3abeac !default;
$info: #ffa068 !default;
$warning: #ffa068 !default;
$danger: #ffa068 !default;
//$white: #05060a !default;
$black: #05060a !default;
//$black: #d7e2e0 !default;
$white: #d7e2e0 !default;
$gray: #a8acab !default;

$default-states: #263148 !default;
$primary-states: #3abeac !default;
$success-states: #3abeac !default;
$info-states: #ffa068 !default;
$warning-states: #ffa068 !default;
$danger-states: #ffa068 !default;
$black-states: #1d253b !default;
$background-black: #1e1e2f !default;

$primary-card-background: #27293d !default;
$primary-card-header: #3b4e5f !default;
$secondary-card-header: #b37f61 !default;

$box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);

$blue: #5e72e4 !default;
$indigo: #5603ad !default;
$purple: #8965e0 !default;
$pink: #f3a4b5 !default;
$red: #fc5173 !default;
$orange: #fb6340 !default;
$yellow: #ffd600 !default;
$green: #27b97a !default;
$teal: #11cdef !default;
$cyan: #2bffc6 !default;

$light-gray: #e3e3e3 !default;
$medium-gray: #dddddd !default;
$dark-gray: #9a9a9a !default;
//$white: #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #e3e3e3 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default; // Line footer color
$gray-700: #525f7f !default; // Line p color
$gray-800: #32325d !default; // Line heading color
$gray-900: #212529 !default;
$link-disabled: #666666 !default;
$transparent-bg: transparent !default;
$light-bg: #f5f6fa !default;
$dark-background: #555555 !default;
