/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import 'cards';
@include ng-zorro-card;

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: $background-black;
}
::-webkit-scrollbar-thumb {
  background: $primary;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

h1,
h2,
h3,
h4 {
  color: $primary;
  font-family: 'Arial', Sans-serif;
}
span,
p {
  font-family: 'Lato', Sans-serif !important;
}
h3 {
  font-size: 16px;
  color: rgba($color: $white, $alpha: 1);
}
.label-with-tooltip {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.ant-divider,
.ant-divider-horizontal {
  border-top: 1px solid $primary !important;
  margin: 10px 0;
}

.full-height {
  height: 100%;
}

.ant-form-item {
  margin: 0 0 5px;
}
.ant-form-item-label {
  padding: 0 0 0 !important;
  label {
    color: white !important;
  }
}

table {
  border-color: transparent !important;
}

.toast-container {
  &.toast-top-center .ngx-toastr {
    width: 400px !important;
  }
  .toast-info,
  .toast-error {
    background-image: none;
  }
  .toast-info {
    background-color: $primary;
    box-shadow: 0 0 12px #12472d !important;
  }
  .toast-error {
    background-color: $secondary;
    box-shadow: 0 0 20px #8b204d !important;
  }
  button.toast-close-button,
  .toast-title,
  .toast-message {
    color: black;
    top: -1em;
  }
}
button.img-button {
  background: none;
  cursor: pointer;
}
button.img-button:active {
  background: rgba($color: $white, $alpha: 1);
}
