@import './variables';
h1,
h2,
h3,
h4 {
  color: $primary;
  font-family: 'Arial', Sans-serif;
}
.col,
.row,
.row-wrap {
  display: flex;
  width: 100%;
  min-height: 100%;
  height: fit-content;
}
.row-wrap {
  flex-flow: row wrap;
  justify-content: center;
  row-gap: 10px;
  overflow-y: auto;
}
.row {
  flex-direction: row;
  .card:not(:last-child) {
    margin-right: 20px;
  }
}
.col {
  flex-direction: column;
  .card:not(:last-child) {
    margin-bottom: 20px;
  }
  .scrollable {
    overflow-y: auto;
  }
}

.card {
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid rgba($primary, 0.3);
  background-color: rgb(22 22 22 / 91%);
  //background-size: 100vw 100vh;
  margin: $contentPadding;

  .divider {
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba($primary, 1) 20%,
      rgba($primary, 1) 80%,
      transparent 100%
    );
    height: 2px;
    width: 100%;
    margin: 40px 0 40px 0;
  }
}

.card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-title {
  text-transform: uppercase;
  font-family: 'Arial', Sans-serif;
  color: $primary;
  width: 100%;
  padding: 20px;
}

.card-body {
  padding: $contentPadding;
  &:first-of-type:not(:first-child) {
    padding-top: 0;
  }
}

span {
  &.value {
    color: white;
    font-size: $valueFontSize;
  }
  &.label {
    color: $labelTextColor;
    margin-bottom: 5px;
    font-size: $labelFontSize;
  }
}
.form-control {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  &.first {
    margin-top: 0;
  }
  input {
    background: $nearblack !important;
    border: none !important;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    height: 30px;
    color: white !important;
    padding-left: 10px;

    &:disabled {
      background-color: rgba($nearblack, 0.3) !important;
    }
  }
}

.form-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: calc($contentPadding * 2);

  button {
    height: 40px;
    min-width: 100px;
  }
}

button {
  color: white;
  margin-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  &.primary {
    background: rgba($semiwhite, 0.3);
    border: 1px solid rgba($primary, 0.5);
    &:disabled {
      opacity: 0.5;
    }
  }
  &.secondary {
    background: none;
    border: none;
    box-shadow: inset 0 0 4px 3px rgba($semiwhite, 0.1);
  }
  &.blank {
    background: none;
    border: none;
  }
  &.close-button {
    position: absolute;
  }
}

@mixin editable-fields {
  .field-with-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    nz-select {
      min-width: 200px;
      max-width: 100%;
    }
    nz-select,
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .field-edit-icon {
      font-size: 15px;
      color: $valueTextColor;
      width: 16px;
      height: 16px;
      margin-left: 10px;
      cursor: pointer;
    }
    input {
      background: none;
      border: 1px solid #ffffff83;
      min-width: 100px;
    }
  }
}

@mixin ng-zorro-card {
  .ant-collapse {
    background: none;
    border: none;
    .ant-collapse-item-disabled .ant-collapse-header {
      color: rgba(255, 255, 255, 0.37) !important;
    }
    .ant-collapse-item-disabled .ant-collapse-content {
      color: rgba(255, 255, 255, 0.37) !important;
    }
    .ant-collapse-header,
    .ant-collapse-arrow {
      color: $valueTextColor !important;
    }
    .ant-collapse-content {
      background: none;
      border-top: 1px solid $semiwhite;
    }
  }

  .ant-picker,
  .ant-select-dropdown {
    border: none !important;
    background-color: #242424 !important;
    .ant-picker-input input {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .ant-radio-button-wrapper {
    color: $valueTextColor;
    font-size: $labelFontSize;
    background: $nearblack !important;
    margin-right: 10px;
    border-color: transparent !important;

    &:focus-within {
      box-shadow: none;
    }
    &::before {
      background-color: transparent;
    }
    &.ant-radio-button-wrapper-checked {
      border-color: $primary !important;
      &::before {
        background-color: $primary;
      }
    }
  }
  .ant-radio-wrapper {
    color: $valueTextColor;
    font-size: $valueFontSize;
    .ant-radio {
      top: 50%;
      bottom: 50%;
      margin: auto;
      .ant-radio-inner {
        background-color: $nearblack !important;
      }
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: $primary !important;
          &::after {
            background-color: $primary !important;
          }
        }
      }
    }
  }
  .ant-select-selection-item,
  .ant-select-selector,
  .ant-select,
  .ant-input {
    background-color: $nearblack !important;
    // border: 1px solid $semiblack;
    border: none !important;
    color: rgba(255, 255, 255, 0.6) !important;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1) !important;
  }
  .ant-select-selection-search-input,
  .ant-select-selection-item {
    box-shadow: none !important;
  }
  .ant-select-selector:active,
  .ant-select-selector:hover {
    border: 1px solid $semiwhite !important;
  }
  //.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item {
    color: $valueTextColor !important;
  }
  .ant-select-item-option-selected,
  .ant-select-item-option-active {
    background-color: lighten($nearblackT, 50%) !important;
  }

  .ant-select-selection-item-selected .ant-select-selection-item-active {
    background-color: lighten($nearblackT, 50%) !important;
  }
}
